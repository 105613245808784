<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewOrganizationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-organization-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Байгууллага нэмэх
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- number -->
          <validation-provider
            #default="validationContext"
            name="Байгууллагын нэр"
            rules="required"
          >
            <b-form-group
              label="Байгууллагын нэр"
              label-for="organization-name"
            >
              <b-form-input
                id="organization-name"
                v-model="organizationData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Байгууллагын нэр"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>    

          <!-- phone -->
          <validation-provider
            #default="validationContext"
            name="Утас"
            rules="required"
          >
            <b-form-group
              label="Утас"
              label-for="organization-phone"
            >
              <b-form-input
                id="organization-phone"
                v-model="organizationData.phone"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Утас"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> 

          <!-- address -->
          <validation-provider
            #default="validationContext"
            name="Хаяг"
            rules="required"
          >
            <b-form-group
              label="Хаяг"
              label-for="organization-address"
            >
              <b-form-textarea 
                id="organization-address"
                v-model="organizationData.address"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                rows="3"
                placeholder="Хаяг"/>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>   

          <!-- timetable -->
          <validation-provider
            #default="validationContext"
            name="Цагийн хуваарь"
            rules="required"
          >
            <b-form-group
              label="Цагийн хуваарь"
              label-for="quil-content"
            >
              <!-- <b-form-input
                
                :state="getValidationState(validationContext)"
                trim
                placeholder="Цагийн хуваарь"
              /> -->
              <quill-editor
                id="quil-content"
                v-model="organizationData.timetable"
                autofocus
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar-sidebar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>   

          <!-- description -->
          <validation-provider
            #default="validationContext"
            name="Нэмэлт тайлбар"
            rules="required"
          >
            <b-form-group
              label="Нэмэлт тайлбар"
              label-for="organization-description"
            >
              <b-form-textarea 
                id="organization-description"
                v-model="organizationData.description"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Нэмэлт тайлбар"
                rows="3"/>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>   

          <!-- sort_order -->
          <validation-provider
            #default="validationContext"
            name="Дараалал"
            rules="required"
          >
            <b-form-group
              label="Дараалал"
              label-for="sort-order"
              :state="getValidationState(validationContext)"
            >
              <b-form-spinbutton
                id="sort-order"
                v-model="organizationData.sort_order"
                min="1"
                max="100"
              />
              <!-- <v-select
                v-model="organizationData.sort_order"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="plan"
              /> -->
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Нэмэх
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Цуцлах
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSpinbutton, BFormTextarea
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
extend('required', {
  ...required,
  message: 'Заавал бөглөх талбар бөглөөгүй байна'
});

// Add the email rule
extend('email', {
  ...email,
  message: 'Заавал и-мэйл бүтэцтэй байна'
});
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import { quillEditor } from 'vue-quill-editor'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSpinbutton,
    BFormTextarea,
    vSelect,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewOrganizationSidebarActive',
    event: 'update:is-add-new-organization-sidebar-active',
  },
  props: {
    isAddNewOrganizationSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankOrganizationData = {
      name: '',
      code: '',
      sort_order: '',
    }

    const organizationData = ref(JSON.parse(JSON.stringify(blankOrganizationData)))
    const resetorganizationData = () => {
      organizationData.value = JSON.parse(JSON.stringify(blankOrganizationData))
    }

    const onSubmit = () => {
      store.dispatch('app-organization/addOrganization', organizationData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-organization-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetorganizationData)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar-sidebar',
      },
      placeholder: 'Цагийн хуваарь',
    }

    return {
      organizationData,
      onSubmit,
      editorOption,
      

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar-sidebar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>