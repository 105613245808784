<template>

  <div>

    <organization-list-add-new
      :is-add-new-organization-sidebar-active.sync="isAddNewOrganizationSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <organizations-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Нэг хуудсанд</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> бичлэг харуулах</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Хайх..."
              />
              <b-button
                variant="primary"
                @click="isAddNewOrganizationSidebarActive = true"
              >
                <span class="text-nowrap">Байгууллага нэмэх</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refOrganizationListTable"
        class="position-relative"
        :items="fetchOrganizations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: is_active -->
        <template #cell(is_active)="data">
          <b-badge
            pill
            :variant="data.item.is_active ? 'success': 'danger'"
          >
            {{ data.item.is_active ? 'идэвхтэй': 'идэвхгүй' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon __table_action_button"
              :to="{ name: 'organizations-view', params: { id: data.item.id } }" v-b-tooltip.hover.v-primary title="Дэлгэрэнгүй"
              size="sm"
            >
              <feather-icon icon="FileTextIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon __table_action_button"
              :to="{ name: 'organizations-edit', params: { id: data.item.id } }" v-b-tooltip.hover.v-primary title="Засах"
              size="sm"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="data.item.is_active ? 'outline-primary' : 'primary'"
            class="btn-icon __table_action_button"
            @click="confirmDelete(data.item)" v-b-tooltip.hover.v-primary :title="'Устгах'"
            size="sm"
          >
            <feather-icon :icon="'TrashIcon'" />
          </b-button>
        </template>
      </b-table>
      <b-modal id="delete-confirm-modal" @ok="deleteOrganization" ok-title="Тийм"
      cancel-title="Үгүй">
        <template #modal-title>
          Устгахдаа итгэлтэй байна уу?
        </template>
        <p>Та энэ байгууллагыг устгахдаа итгэлтэй байна уу?</p>
      </b-modal>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Нийт {{ dataMeta.of }}-н {{ dataMeta.from }} - {{ dataMeta.to }} бичлэгийг  харуулж байна</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <!-- <b-pagination
              v-model="currentPage"
              :total-rows="totalOrganizations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination> -->
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import OrganizationsListFilters from './OrganizationsListFilters.vue'
import useOrganizationsList from './useOrganizationsList'
import organizationStoreModule from '../organizationStoreModule'
import OrganizationListAddNew from './OrganizationListAddNew.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    OrganizationsListFilters,
    OrganizationListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
  setup() {
    const ORGANIZATION_APP_STORE_MODULE_NAME = 'app-organization'

    // Register module
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME)) store.registerModule(ORGANIZATION_APP_STORE_MODULE_NAME, organizationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME)) store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME)
    })

    const isAddNewOrganizationSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Админ', value: 'admin' },
      { label: 'Эрхлэгч', value: 'author' },
      { label: 'Засварлагч', value: 'editor' },
      { label: 'Дэмжигч', value: 'maintainer' },
      { label: 'Дагагч', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Үндсэн', value: 'basic' },
      { label: 'Байгууллага', value: 'company1' },
      { label: 'Аж ахуй нэгж', value: 'enterprise' },
      { label: 'Баг', value: 'team' },
    ]


    const statusOptions = [
      // { label: 'Хүлээгдэж буй', value: 'pending' },
      { label: 'Идэвхитэй', value: 'true' },
      { label: 'Идэвхигүй', value: 'false' },
    ]

    const {
      fetchOrganizations,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrganizationListTable,
      refetchData,

      // UI
      resolveOrganizationRoleVariant,
      resolveOrganizationRoleIcon,
      resolveOrganizationStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useOrganizationsList()

    

    return {

      // Sidebar
      isAddNewOrganizationSidebarActive,

      fetchOrganizations,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrganizationListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveOrganizationRoleVariant,
      resolveOrganizationRoleIcon,
      resolveOrganizationStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  data() {
    return {
      organizationToDelete: null,
    };
  },
  methods: {
    confirmDelete(organizationData) {
      this.organizationData = organizationData;
      this.$bvModal.show('delete-confirm-modal');
    },
    deleteOrganization () {
      if(this.organizationData){
        store.dispatch('app-organization/deleteOrganization', this.organizationData)
        .then(response => {
          console.log(response)
          if (response.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            setTimeout(() => {
              this.$router.go()  // Refresh the current route
            }, 1000) 
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Алдаа гарлаа',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        })
        .finally(() => {
          this.organizationData = null; // Reset the organization after deletion
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.__table_action_button {
  margin-right: 5px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
